<template>
  <div class="form--create-report flex flex-h-center flex-v-center bg-grey-xlight">
    <div class="form__content">
      <h2 class="center">Create Report from Portfolio</h2>
      <label for="report-type">Select Report Type</label>
      <radio-buttons
        :key="radioButtonsKey"
        id="report-type"
        :options="reportTypes"
        @input="handleRadioButtonClick">
      </radio-buttons>

      <form-create-multi-site-report
        v-if="multiSiteReport"
        :key="PortfolioMultiFormKey"
        :sites="portfolioSites"
        :apiEndPoint="apiEndPoint"
        :portfolioId="portfolioId">
        >
      </form-create-multi-site-report>

      <form-create-portfolio-tnfd-locate-report
        v-if="tnfdReport"
        :key="PortfolioMultiFormKey"
        :sites="portfolioSites"
        :apiEndPoint="apiEndPoint"
        :portfolioId="portfolioId"
        :sitesOptionsLimit="sitesOptionsLimit"
        :dprSitesQuota="dprSitesQuota"
      >
      </form-create-portfolio-tnfd-locate-report>
    </div>

  </div>
</template>

<script>
  import axios from 'axios'
  import RadioButtons from '../form_fields/RadioButtons'
  import FormCreateMultiSiteReport from '../form/FormCreateMultiSiteReport'
  import FormCreatePortfolioTnfdLocateReport from '../form/FormCreatePortfolioTnfdLocateReport'

  import { DEFAULT_SITES_OPTIONS_LIMIT } from '../../utilities/constants'

  import helpers from '../../utilities/helpers.js'
  import { eventHub } from '../../ibat.js'

  export default {
    name: 'form-portfolio-report',

    components: { 
      RadioButtons,
      FormCreateMultiSiteReport,
      FormCreatePortfolioTnfdLocateReport,
    },

    props: {
      formFields: { required: true, type: Object },
      reportFormFields: { required: true, type: Object },
      id: { required: true, type: Number }
    },

    created() {
      eventHub.$on('modalClosed', this.resetForm)
    },

    mounted() {
      if (this.formFields.sites.length > 0) {
        this.portfolioSites = this.formFields.sites
      }
      this.resetForm()
      this.apiEndPoint = `/portfolios/${this.formFields.id}/fetch_portfolio_sites`
    },

    data () {
      return {
        // selectedSite: this.site || '',
        PortfolioMultiFormKey: helpers.randomNumber(),
        portfolioSites: [],
        apiEndPoint: "",
        radioButtonsKey: helpers.randomNumber(),
        selectedReportType: this.reportTypes ? this.reportTypes[0] : {},
        sitesOptionsLimit: this.sitesOptionsLimit,
        dprSitesQuota: this.dprSitesQuota
      }
    },

    methods: {
      resetForm() {
        this.PortfolioMultiFormKey += 1
        const csrf = document.querySelectorAll('meta[name="csrf-token"]')[0].getAttribute('content')
        axios.defaults.headers.common['X-CSRF-Token'] = csrf
        axios.defaults.headers.common['Accept'] = 'application/json'

        axios.get(`/portfolios/${this.formFields.id}`)
        .then((response) => {
          this.portfolioSites = response.data.sites
        })
        .catch(function (error) {
          console.log(error)
        })
      },
      handleRadioButtonClick(value) {
        if((value.id !== this.selectedReportType.id) ){
          this.selectedReportType =  value
          // this.setSelectedSite(this.site)
          this.setSiteOptionsLimit()
          this.setDprSitesQuota()
        }
      },
      setSiteOptionsLimit() {
        this.sitesOptionsLimit = this.selectedReportType.sites_options_limit || DEFAULT_SITES_OPTIONS_LIMIT
      },
      setDprSitesQuota() {
        this.dprSitesQuota = this.selectedReportType.dpr_sites_quota || 0
      },
    },

    computed: {
      sites () {
        return this.formFields.sites ? this.formFields.sites : []
      },

      portfolioId() {
        return this.formFields.id
      },

      reportTypes () {
        return this.reportFormFields.types ? this.reportFormFields.types : []
      },
      multiSiteReport () {
        return this.selectedReportType.slug == "multi_site"
      },
      tnfdReport () {
        return this.selectedReportType.slug == "tnfd"
      }
    }
  }
</script>
