<template>
  <div>
    <div class="form__field">
      <label for="type">Type of enquiry</label>
      <select id="type" v-model="form.type" @change="handleTypeChange">
        <option v-for="option in enquiryOptions" :key="option" :value="option">{{ option }}</option>
      </select>
    </div>

    <div class="form__field" id="additional-options" v-if="showAdditionalOptions">
      <label>Intended use case</label>
      <ul class="use-case-list">
        <li v-for="option in intendedUseCaseOptions" :key="option" class="form__field--radio">
          <input type="checkbox" :id="'use_case_' + option" :value="option" v-model="form.useCase" />
          <span :for="'use_case_' + option" class="collection_check_box form__field-text">{{ option }}</span>
        </li>
      </ul>
    </div>

    <!-- Hidden input fields to synchronize data with the form -->
    <input type="hidden" name="type" :value="form.type" />
    <input type="hidden" name="use_case[]" v-for="option in form.useCase" :key="option" :value="option" />
  </div>
</template>

<script>
export default {
  props: {
    enquiryOptions: {
      type: Array,
      required: true
    },
    intendedUseCaseOptions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      form: {
        type: this.enquiryOptions[0] || '',
        useCase: []
      },
      showAdditionalOptions: false
    };
  },
  methods: {
    handleTypeChange() {
      if (this.form.type === 'I have a query about annual IBAT subscription/ PAYG') {
        this.showAdditionalOptions = true;
      } else {
        this.showAdditionalOptions = false;
        this.form.useCase = []; // Clear the checkboxes
      }
    }
  }
};
</script>
<style scoped>
.form__field {
  margin-bottom: 1rem;
}
.use-case-list {
  list-style-type: none; /* Remove default list markers */
  padding: 0; /* Remove default padding */
}

.use-case-list li {
  margin-bottom: 0.5rem;
  border: none; /* Remove any default border */
}
</style>
